import {React} from 'react'
import Content from '../layout/Content'
const Logout = () => {
  return (
    <Content title='UCLA - Logged Out' pageTitle='LOGGED OUT'>
      <div id="page_header_section"> 
           
        <iwe-message class="info-normal pika-title">
          <p class="fa-lg"><strong>You have successfully logged out.</strong></p>
        </iwe-message>
        
        <p>
          <img
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAM0lEQVR4AWMgB0yJ+30eiOdjkyPXwAQg/k8jQ0cNBTGoiUEGOlABz4e5kO5hOGoY4bwMALl2t++Ujt73AAAAAElFTkSuQmCC'
            alt='Sign In on MyUCLA Home'
          />
          <span>&nbsp;&nbsp;<a href="http://my.ucla.edu/" title="Sign In on MyUCLA Home">Sign In on MyUCLA Home</a></span>
        </p>
        <iwe-message class="warning-normal">
          <i className="fa fa-exclamation-triangle"></i> If this is a public or shared computer, you must <strong>close ALL your browser windows</strong> in order to protect your information. 
            For Chrome users, make sure the &ldquo;Settings: On startup: Continue where you left off&rdquo; option is <strong>not</strong> selected. 
        </iwe-message>

      </div>  
    </Content>
  )
}

export default Logout