import React, { useState, Fragment } from 'react';
import AppRouter from './components/AppRouter'
import Header from './components/layout/Header'
//import {AuthContext} from "./components/context/Auth"

function App() {
  let existingUser = localStorage.getItem("user");
  if(existingUser){
    existingUser=JSON.parse(existingUser)
  }
  const [user, setUser] = useState(existingUser)
  const [tokenValid, setTokenValid] = useState(null)
  const homeUrl = useState('/')

  const setAuthUser = (user)=>{
    let userString = user
    if(user){
      userString = JSON.stringify(user)
    }
    localStorage.setItem("user", userString)
    console.log("setting user!!")
    setUser(JSON.parse(userString))
  }
  const setUserTokenValid = (valid)=>{
    console.log("setting token  status")
    setTokenValid(valid)
  }
//<AuthContext.Provider value ={{user, setUser: setAuthUser, tokenValid, setTokenValid: setUserTokenValid}}> 
//</AuthContext.Provider>

  return (
    <Fragment>
      <layout-pagewrap unresolved>
        <Header menuData='' homeUrl='' notices=''/>
        <AppRouter />
      </layout-pagewrap>
      <layout-footer unresolved></layout-footer>
    </Fragment>
  );
}

export default App;