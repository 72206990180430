import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Logout from './misc/Logout'
function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Logout/>}/>
      </Routes>
    </Router>
  )
}

export default AppRouter